<template>
  <div>
    <router-view />


    <div class="menu">
      <router-link
        tag="div" 
        class="menu-item"
        v-for="item in menuList" 
        :key="item.id"
        :to="item.href"
      >
        <img :src="$route.path === item.href ? item.active : item.inactive" />
        <p :class="$route.path === item.href ? 'active' : ''">{{item.name}}</p>
      </router-link>
    </div>
    <!-- <van-tabbar route active-color="#79cdca">
      <van-tabbar-item 
        v-for="item in menuList"
        :key="item.id"
        :to="item.href"
      >
        <span>{{item.name}}</span>
        <template #icon="props">
          <img :src="props.active ? item.active : item.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>

<script>
  export default {
    mounted(){
      // this.getInfo();
      document.title = '明九章';
    },
    data () {
      return {
        mobile: "",
        active: 0,
        menuList: [
          {
            id: 0,
            active: require('../image/menu/home-act.png'),
            inactive: require('../image/menu/home.png'),
            name: '首页',
            href: '/main/home'
          },
          {
            id: 1,
            active: require('../image/menu/zuoye-act.png'),
            inactive: require('../image/menu/zuoye.png'),
            name: '我的作业',
            href: '/main/answer'
          },
          {
            id: 2,
            active: require('../image/menu/test-act.png'),
            inactive: require('../image/menu/test.png'),
            name: '测试成绩',
            href: '/main/test'
          },
          {
            id: 3,
            active: require('../image/menu/my-act.png'),
            inactive: require('../image/menu/my.png'),
            name: '我的',
            href: '/main/my'
          }
        ]
      }
    },
    computed: {
        changeRouter() {
            let path = this.$route.path;
            if (path.indexOf('/main/kindManage') > -1) {
                path = '/main/kindList'
            }
            return path;
        }
    },
    methods: {
      // getInfo() {
      //   this.$http.get(`/api/user/getInfo`, {
      //   })
      //   .then((res) => {
      //     localStorage.setItem('ng-data', JSON.stringify(res.data.data));
      //     this.info = res.data.data;
      //   }).catch((err) => {
      //       this.validErrorInfo = err.errorMsg;
      //   })
      // },
    }
  }
</script>

<style lang="less" scoped>
    .menu {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 7.5rem;
      height: 1rem;
      width: 100%;
      background-color: white;
      display: flex;
      box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.03);
      z-index: 1000000;
      .menu-item {
        width: 25%;
        text-align: center;
        cursor: pointer;
        img {
          width: 0.48rem;
          height: 0.48rem;
          display: block;
          margin: 0.05rem auto 0;
        }
        p {
          &.active {
            color: #79cdca;
          }
          font-size: 0.24rem;
          margin-top: 0.05rem;
        }
      }
    }
</style>
